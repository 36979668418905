import React from 'react'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'
import './Achievements.css'

function Achievements() {
    return (
        <div className="achievement-box my-absolute my-flex ai-center jc-space-bw">

            <FadeInDivOnScroll class='achievement-left-container achievement-left o-hidden'>
                <div className='achievement-left-container my-flex ai-center jc-center'>
                    <img src="homepage_assets/gif/achievement-giff.gif" alt="ball gif" />
                </div>
            </FadeInDivOnScroll >

            <div className='achievement-right'>
                <FadeInDivOnScroll class='achievement-right-head-container achievement-right-head sk-modernist-b'>
                    <div className='achievement-right-head-container'>
                        We are Designers, That Evoke Emotions
                    </div>
                </FadeInDivOnScroll >

                <FadeInDivOnScroll class='achievement-right-body-container achievement-right-body sk-modernist'>
                    <div className='achievement-right-body-container'>
                        Our journey is defined by the successful completion of numerous projects and long-lasting relationships with clients across diverse industries. Each project reflects our dedication to quality, innovation, and results-driven solutions. These milestones represent not just numbers but the trust and satisfaction of our clients, along with the growth we've achieved together.
                    </div>
                </FadeInDivOnScroll >

                <FadeInDivOnScroll class='achievement-right-badges-container achievement-right-badges'>
                    <div className='achievement-right-badges-container my-flex jc-space-bw'>
                        <div className='achievement-badge'>
                            <span className='achievement-value'>10+</span>
                            <span className='achievement-name'>Projects Completed</span>
                        </div>
                        <div className='achievement-badge'>
                            <span className='achievement-value'>05</span>
                            <span className='achievement-name'>Creative Minds</span>
                        </div>
                        <div className='achievement-badge'>
                            <span className='achievement-value'>03+</span>
                            <span className='achievement-name'>Years of Experience</span>
                        </div>
                        <div className='achievement-badge'>
                            <span className='achievement-value'>10+</span>
                            <span className='achievement-name'>Clients</span>
                        </div>
                    </div>
                </FadeInDivOnScroll >
            </div>
        </div>
    )
}

export default Achievements