import React from 'react'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'
import { Link } from 'react-router-dom'
import './ExperienceDesign.css'

function ExperienceDesign() {
    return (
        <div className='mobile-home-experience-design'>
            <div className="mobile-home-exp-mini-heading sk-modernist-b">WHY EXPERIENCE DESIGN</div>
            <FadeInDivOnScroll class='f-i-f-b'>
                <div className="mobile-home-exp-heading sk-modernist">Experience design (XD) plays a crucial role in the success of digital products by ensuring they are user-centered, functional, and emotionally engaging.</div>
            </FadeInDivOnScroll>
            <FadeInDivOnScroll class='f-i-f-b'>
                <div className="mobile-home-exp-desc sk-modernist">At our agency, we help you by deeply understanding your users through research and data-driven insights, then translating that knowledge into intuitive, engaging designs. We combine UX strategy, visual design, and seamless interaction to create digital experiences that not only meet user needs but also align with your business goals, driving conversion and customer loyalty. Our end-to-end service ensures that every touchpoint, from concept to launch, is optimized for impact and long-term success.</div>
            </FadeInDivOnScroll>
            <div className='mobile-home-exp-work-connect my-flex jc-space-bw '>
                <div className="mobile-home-exp-our-work sk-modernist">Our Work</div>

                <div className="mobile-home-exp-connect my-flex my-button-click my-no-select">
                    <div className="mobile-home-exp-lets-connect sk-modernist"><Link to='/contact'>Let's Connect</Link></div>
                    <img src="homepage_assets/images/icons/hidden-part-arrow-right-circle.png" alt="arrow right icon" />
                </div>
            </div>
        </div>
    )
}

export default ExperienceDesign
