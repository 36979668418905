import React from 'react'
import './Navbar.css'
import {Link} from 'react-router-dom'

function Navbar() {

    const scrollToBottomContact = () => {
        window.scrollTo({
            top: 5200,  // Specify the vertical position (y-axis)
            left: 0,   // You can specify horizontal position (x-axis) if needed
            behavior: 'smooth'  // Optional for smooth scrolling
        });
    };

    return (
        <div className="navbar my-flex my-absolute">
            <div className="navbar-logo my-flex ai-center">
                <Link to="/" className='my-flex'>
                    <img className='my-pointer' src="navbar_assets/tevah-logo.png" alt="tevah logo"/>
                </Link>
            </div>

            <div className="navigation-items my-flex my-absolute ai-center sk-modernist">
                <div>
                    <Link to='/'>Services</Link>
                </div>
                <div>
                    <Link to='/'>Work</Link>
                </div>
                <div>
                    <Link to='/'>About</Link>
                </div>
                <div className="navigation-items-contact my-pointer my-flex ai-center jc-center sk-modernist-b" onClick={scrollToBottomContact}>Contact</div>
            </div>

            <div className="navigation-options my-flex ai-center my-absolute my-button-click">
                <img src='navbar_assets/navbar-options.png' alt=''></img>
            </div>

            <div className="navigation-sidebar">
                <div>
                    <Link to='/services'>Services</Link>
                </div>
                <div>
                    <Link to='/work'>Work</Link>
                </div>
                <div>
                    <Link to='/about'>About</Link>
                </div>
                <div>Contact</div>
            </div>
        </div>
    )
}

export default Navbar