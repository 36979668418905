import React from 'react'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'
import './Projects.css'

function ProjectsBox(props) {

    let animation1 = 'home-project-x home-project-x-anim-1 my-flex o-hidden ai-center jc-center'
    let animation2 = 'home-project-x home-project-x-anim-2 my-flex o-hidden ai-center jc-center'

    return (
        <div className={props.animationHighDuration ? animation2 : animation1}>
            <div className="home-project-x-most-upper-layer my-pointer my-absolute my-flex jc-space-bw">
                <div className="home-project-x-most-upper-layer-left ">
                    <div className="home-project-x-most-upper-layer-head sk-modernist">{props.head}</div>
                    <div className="home-project-x-most-upper-layer-body sk-modernist-l">{props.body}</div>
                </div>

                <div className="home-project-x-most-upper-layer-right my-button-click-w">
                    <img src="homepage_assets/images/icons/inclined-arrow.png" alt="inclined arrow"/>
                </div>
            </div>

            <img className="home-project-x-upper-layer my-absolute" src="homepage_assets/images/shadows/project-shadow.png" alt="shadow"/>
            <img src={props.source} alt="project images"/>
        </div>
    )
}

function Projects(props) {
    return (
        <>
            <div className="home-projects-heading my-flex ai-center jc-space-bw">
                <FadeInDivOnScroll class='home-projects-heading-quote sk-modernist f-i-r-l'>
                    Crafting impactful experiences through design
                </FadeInDivOnScroll>

                <div className='home-projects-right-arrow my-flex jc-center ai-center o-hidden'>
                    <img className='my-button-click-w' src="homepage_assets/images/icons/arrow-right-circle.png" alt="arrow right"/>
                </div>
            </div>

            <div className="home-projects-completed my-flex ai-center">
                <FadeInDivOnScroll class='home-projects-completed-show-count f-i-f-b'>
                    <div className='home-projects-completed-count sk-modernist-b'>10+</div>
                    <div className='home-projects-completed-tag sk-modernist-b'>Projects Completed</div>
                </FadeInDivOnScroll>

                <div className='home-projects-vertical-line'></div>

                <FadeInDivOnScroll class='home-projects-explanation sk-modernist f-i-r-l'>
                    we specialize in weaving captivating narratives through design. Dive into our portfolio and explore the stories we've brought to life through our innovative designs and creative solutions.
                </FadeInDivOnScroll>
            </div>

            <div className="home-projects-demo">
                <FadeInDivOnScroll class='home-project-x-anim-1'>
                    <ProjectsBox source={"homepage_assets/images/project1.png"} head='Capable Website'
                                 body='Crafted this edtech product, An ecosystem connecting students, companies, and colleges for jobs, upskilling, training, and mentorship.'></ProjectsBox>
                </FadeInDivOnScroll>

                <FadeInDivOnScroll class='home-project-x-anim-2'>
                    <ProjectsBox source={"homepage_assets/images/project2.png"} head='Seeko Paper Trading App'
                                 body='Designed the experience for Seeko, which allows user below 18 year who can’t have a demat account to learn how stock market works.' animationHighDuration={true}></ProjectsBox>
                </FadeInDivOnScroll>

                <FadeInDivOnScroll class='home-project-x-anim-1'>
                    <ProjectsBox source={"homepage_assets/images/project3.png"} head='Edland  - Study Abroad'
                                 body='Edland is helping students to pursue their dream study in abroad. We helped Edland to create an intuitive and new gen website experience.'></ProjectsBox>
                </FadeInDivOnScroll>

                <FadeInDivOnScroll class='home-project-x-anim-2'>
                    <ProjectsBox source={"homepage_assets/images/project4.png"} head='Collab' body='An application designed for sharing homework, feedback and other inputs between teachers and parents.'
                                 animationHighDuration={true}></ProjectsBox>
                </FadeInDivOnScroll>
            </div>

        </>
    )
}

export default Projects