import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom';
import './NavbarMobile.css'

function NavbarMobile() {

    const [showNavbar, setShowNavbar] = useState(true);
    const [lastScrollPos, setLastScrollPos] = useState(0);

    useEffect(() => {

        const handleScroll = () => {
            const currentScrollPos = window.pageYOffset;

            if (currentScrollPos > lastScrollPos) {
                setShowNavbar(false); // Hide navbar on scroll down
            } else {
                setShowNavbar(true); // Show navbar on scroll up
            }
            setLastScrollPos(currentScrollPos);
        };

        window.addEventListener('scroll', handleScroll);

        return () => window.removeEventListener('scroll', handleScroll);
    }, [lastScrollPos]);

    return (
        <div className={`mobile-navbar my-no-select my-flex ${showNavbar ? 'visible' : 'hidden'}`}>
            <div className="mobile-navbar-logo my-flex ai-center">
                <Link to="/" className='my-flex'>
                    <img className='my-pointer' src="navbar_assets/tevah-logo.png" alt="tevah logo" />
                </Link>
            </div>

            <div className="mobile-navigation-options my-flex ai-center my-absolute my-button-click">
                <img src='navbar_assets/navbar-options.png' alt=''></img>
            </div>

            <div className="mobile-navigation-sidebar">
                <div>
                    <Link to='/services'>Services</Link>
                </div>
                <div>
                    <Link to='/work'>Work</Link>
                </div>
                <div>
                    <Link to='/about'>About</Link>
                </div>
                <div >Contact</div>
            </div>
        </div>
    )
}

export default NavbarMobile