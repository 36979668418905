import React, {useEffect, useRef, useState} from 'react'
import HomeIntroduction from './Introduction/HomeIntroduction'
import Navbar from '../WebComponents/Navbar/Navbar'
import Projects from './Projects/Projects'
import Clients from './Clients/Clients'
import Whatwedo from './Whatwedo/Whatwedo'
import Achievements from './Achievements/Achievements'
import BottomContact from '../WebComponents/BottomContact/bottomContact'
import BottomAbout from '../WebComponents/BottomAbout/bottomAbout'
import './Homepage.css'

function Homepage() {

    const targetRef = useRef(null);
    const [isScrolling, setIsScrolling] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setIsScrolling(true);
            } else {
                setIsScrolling(false)
            }
        };
        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="homepage my-flex jc-center my-no-select">

            <div className="homepage-top-lights">
                <img className="homepage-top-light-left my-absolute ai-center" src="homepage_assets/images/shadows/light1.png" alt="lights"/>
                <img className="homepage-top-light-right my-absolute ai-center" src="homepage_assets/images/shadows/light2.png" alt="lights"/>
            </div>

            <Navbar targetRef={targetRef}></Navbar>

            {/* ////////// INTRODUCTION ////////// */}

            <HomeIntroduction></HomeIntroduction>

            <div className={isScrolling ? 'projects-to-rest-moved my-absolute' : 'projects-to-rest my-absolute m-i-f-b'}>

                <div className="home-moving-box o-hidden">
                    <video className="autoplay-video my-absolute" autoPlay='autoplay' muted loop>
                        <source src="homepage_assets/videos/moving_box_video.mp4" type="video/mp4"/>
                    </video>
                </div>

                {/* ////////// PROJECTS ////////// */}

                <Projects></Projects>

                {/* ////////// CLIENTS ////////// */}

                <Clients></Clients>

                {/* ////////// WHAT WE DO ////////// */}

                <Whatwedo></Whatwedo>

                {/* ////////// ACHIEVEMENTS ////////// */}

                <div className="achieve-top-lights my-flex">
                    <img src="homepage_assets/images/shadows/light1.png" alt="" className="achieve-light1"/>
                    <img src="homepage_assets/images/shadows/light2.png" alt="" className="achieve-light2 my-absolute"/>
                </div>

                <Achievements></Achievements>

                <div className="achieve-bottom-lights my-flex my-absolute">
                    <img src="homepage_assets/images/shadows/light1.png" alt="" className="achieve-bottom-light1"/>
                    <img src="homepage_assets/images/shadows/light2.png" alt="" className="achieve-bottom-light2"/>
                </div>

                {/* ////////// CONTACT & ABOUT ////////// */}

                <BottomContact></BottomContact>

                <BottomAbout></BottomAbout>

            </div>
        </div>
    )
}

export default Homepage