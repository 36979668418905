import React from 'react'
import './bottomAbout.css'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'

function BottomAbout() {
    return (
        <div className="project-about my-flex ai-center">
            <div className="project-about-top my-flex jc-space-bw">
                <div className="project-about-lists my-flex jc-space-bw">
                    <FadeInDivOnScroll class='project-about-list-1-animation'>
                        <div className="project-about-lists-x">
                            <p>Company</p>
                            <div className='project-about-lists-x-li'>
                                <div>About</div>
                                <div>Services</div>
                                <div>Domains</div>
                                <div>Works</div>
                                <div>Careers</div>
                                <div>Contact</div>
                            </div>
                        </div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='project-about-list-2-animation'>
                        <div className="project-about-lists-x">
                            <p>Services</p>
                            <div className='project-about-lists-x-li'>
                                <div>Experience Design</div>
                                <div>Brand Identity Design</div>
                                <div>Micro Animation</div>
                                <div>Graphic Design</div>
                                <div>Presentation Design</div>
                                <div>Digital Marketing</div>
                            </div>
                        </div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='project-about-list-3-animation'>
                        <div className="project-about-lists-x">
                            <p>Company</p>
                            <div className='project-about-lists-x-li'>
                                <div>About us</div>
                                <div>Careers</div>
                                <div>Contact us</div>
                                <div>Lift Media</div>
                            </div>
                        </div>
                    </FadeInDivOnScroll>
                </div>

                <div className="project-about-subscribe">
                    <div className="project-about-subscribe-head my-bold">Subscribe to our newsletter</div>
                    <div className="project-about-subscribe-input my-flex ai-center">
                        <input type="text" placeholder="Email address"/>
                        <div className="project-about-subscribe-arrow-button my-flex ai-center jc-center my-pointer"><img src="homepage_assets/images/icons/arrow-right.png" alt="arrow button"/></div>
                    </div>
                    <div className="project-about-subscribe-desc">By submitting, you agree to receive important updates and insights via email.</div>
                </div>
            </div>

            <div className="project-about-hr"></div>

            <div className="project-about-bottom my-flex jc-space-bw">
                <div className="project-about-bottom-left my-flex jc-space-bw ai-center">
                    <FadeInDivOnScroll class='project-about-list-1-animation'>
                        <img className="project-about-logo" src='navbar_assets/tevah-logo.png' alt='tevah logo'/>
                    </FadeInDivOnScroll>

                    <FadeInDivOnScroll class='project-about-list-2-animation'>
                        <div className="project-about-privacy-cookies">
                            <span>Privacy Policy</span>
                            <span>Cookies</span>
                        </div>
                    </FadeInDivOnScroll>
                </div>

                <div className="project-about-bottom-right my-flex jc-space-bw ai-center">
                    <FadeInDivOnScroll class='project-about-social-4-animation'>
                        <div className="project-about-privacy-cookies">@ 2024. All Rights Reserved</div>
                    </FadeInDivOnScroll>

                    <FadeInDivOnScroll class='project-about-social-4-animation'>
                        <div className="project-about-social my-button-click-w"><img src="homepage_assets/images/social/instagram.png" alt=""/></div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='project-about-social-3-animation'>
                        <div className="project-about-social my-button-click-w"><img src="homepage_assets/images/social/linkedin.png" alt=""/></div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='project-about-social-2-animation'>
                        <div className="project-about-social my-button-click-w"><img src="homepage_assets/images/social/facebook.png" alt=""/></div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='project-about-social-1-animation'>
                        <div className="project-about-social my-button-click-w"><img src="homepage_assets/images/social/x.png" alt=""/></div>
                    </FadeInDivOnScroll>
                </div>
            </div>
        </div>
    )
}

export default BottomAbout