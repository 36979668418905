import React from 'react'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'
import './Clients.css'

function Clients() {
    return (
        <div className="home-clients my-flex ai-center jc-space-bw">
            <div className='home-clients-text sk-modernist'>
                <FadeInDivOnScroll class='home-clients-text-head f-i-r-l'>Clients</FadeInDivOnScroll>
                <FadeInDivOnScroll class='home-clients-text-body'>Explore our client roster across industries, benefiting from our tailored solutions that enhance digital experiences.</FadeInDivOnScroll>
            </div>
            <FadeInDivOnScroll class='home-clients-images-animation'>
                <div className='home-clients-images my-flex jc-space-bw'>
                    <div class="home-client-faded-box-1"></div>
                    <img src="homepage_assets/images/clients-img.png" alt="clients images"/>
                    <div class="home-client-faded-box-2"></div>
                </div>
            </FadeInDivOnScroll>
        </div>
    )
}

export default Clients