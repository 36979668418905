import React from 'react'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'
import './BottomAboutMobile.css'

function BottomAboutMobile() {
    return (
        <div className='mobile-bottom-about my-absolute'>
            <div className="mobile-bottom-about-services">
                <FadeInDivOnScroll class='f-i-r-l'>
                    <div className="mobile-bottom-about-services-head sk-modernist-b">Services</div>
                </FadeInDivOnScroll>

                <div className="mobile-bottom-about-services-list sk-modernist-l">
                    <FadeInDivOnScroll class='f-i-r-l'>
                        <div className="mobile-bottom-about-services-item">Experience Design</div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='f-i-r-l'>
                        <div className="mobile-bottom-about-services-item">Brand Identity Design</div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='f-i-r-l'>
                        <div className="mobile-bottom-about-services-item">Micro Animation</div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='f-i-r-l'>
                        <div className="mobile-bottom-about-services-item">Graphic Design</div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='f-i-r-l'>
                        <div className="mobile-bottom-about-services-item">Presentation Design</div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='f-i-r-l'>
                        <div className="mobile-bottom-about-services-item">Digital Marketing</div>
                    </FadeInDivOnScroll>
                </div>
            </div>

            <div className="mobile-bottom-about-queries">
                <FadeInDivOnScroll class='f-i-r-l'>
                    <div className="mobile-bottom-about-queries-head sk-modernist-b">For any queries</div>
                </FadeInDivOnScroll>
                <FadeInDivOnScroll class='f-i-r-l'>
                    <div className="mobile-bottom-about-queries-email sk-modernist-l my-no-select my-button-click">info@tevahdesign.com</div>
                </FadeInDivOnScroll>
            </div>

            <div className="mobile-bottom-about-follow my-flex ai-center ">
                <div className="mobile-bottom-about-follow-head sk-modernist-b">Follow our work</div>

                <div className="mobile-bottom-about-social-media my-flex ai-center jc-space-bw">
                    <FadeInDivOnScroll class='z-i-m-1'>
                        <div className="mobile-bottom-about-social-media-item"><img src='homepage_assets/images/social/instagram.png' alt=''></img></div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='z-i-m-2'>
                        <div className="mobile-bottom-about-social-media-item"><img src='homepage_assets/images/social/linkedin.png' alt=''></img></div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='z-i-m-3'>
                        <div className="mobile-bottom-about-social-media-item"><img src='homepage_assets/images/social/facebook.png' alt=''></img></div>
                    </FadeInDivOnScroll>
                    <FadeInDivOnScroll class='z-i-m-4'>
                        <div className="mobile-bottom-about-social-media-item"><img src='homepage_assets/images/social/x.png' alt=''></img></div>
                    </FadeInDivOnScroll>
                </div>
            </div>

            <div className="mobile-bottom-about-tevah-logo my-flex ai-center">
                <img src="navbar_assets/tevah-logo.png" alt="" />
                <div className="mobile-bottom-about-arc-of-design sk-modernist-l">The Arc of Design</div>
            </div>

            <div className="mobile-bottom-about-privacy-cookies my-flex ai-center jc-center sk-modernist">
                <div className="mobile-bottom-about-privacy my-no-select my-button-click">Privacy Policy</div>
                <div className="mobile-bottom-about-pc-vertical-line"></div>
                <div className="mobile-bottom-about-cookies my-no-select my-button-click">Cookies</div>
            </div>

            <div className="mobile-bottom-about-rights sk-modernist-l my-flex jc-center">© 2024. All Rights Reserved</div>
        </div>
    )
}

export default BottomAboutMobile