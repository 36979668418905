import React from 'react'
import './ProjectsMobile.css'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'

function ProjectsMobileContainer(props) {
    return (
        <div className='mobile-projects-container'>
            <FadeInDivOnScroll class='z-i-m'>
                <div className="mobile-projects-image-container o-hidden">
                    <img src={props.source} alt="" />
                </div>
            </FadeInDivOnScroll>
            <FadeInDivOnScroll class='z-i-m'>
                <div className="mobile-projects-text-container sk-modernist">{props.text}</div>
            </FadeInDivOnScroll>
        </div>
    )
}

function ProjectsMobile() {

    return (
        <div className='mobile-projects my-flex ai-center'>
            <ProjectsMobileContainer source='homepage_assets/images/project1.png' text='Capabl: Crafted this edtech product, An ecosystem connecting students, companies, and colleges for jobs, upskilling, training, and mentorship.' />
            <ProjectsMobileContainer source='homepage_assets/images/project2.png' text='Seeko: Designed the experience for Seeko, which allows user below 18 year who can’t have a demat account to learn how stock market works' />
            <ProjectsMobileContainer source='homepage_assets/images/project4.png' text='Collab: An application designed for sharing homework, feedback and other inputs between teachers and parents.' />

            <div className="mobile-projects-view-all-works my-flex ai-center jc-center sk-modernist my-no-select my-button-click">View All Works</div>
        </div>
    )
}

export default ProjectsMobile