import React from 'react'
import './OurVision.css'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'

function OurVision() {
    return (
        <div className='mobile-our-vision'>
            <FadeInDivOnScroll class='f-i-f-b'>
                <div className="mobile-our-vision-mini-head sk-modernist-b">OUR VISION</div>
            </FadeInDivOnScroll>
            <FadeInDivOnScroll class='f-i-f-b'>
                <div className="mobile-our-vision-head sk-modernist">We are Designers, That Evoke Emotions</div>
            </FadeInDivOnScroll>
            <FadeInDivOnScroll class='f-i-f-b'>
                <div className="mobile-our-vision-body sk-modernist">Our journey is defined by the successful completion of numerous projects and long-lasting relationships with clients across diverse industries. Each project reflects our dedication to quality, innovation, and results-driven solutions.</div>
            </FadeInDivOnScroll>
            <div className="mobile-our-vision-grid">
                <FadeInDivOnScroll class='f-i-f-b'>
                    <div className='mobile-our-vision-badge'>
                        <span className='mobile-our-vision-badge-value'>10+</span>
                        <span className='mobile-our-vision-badge-name'>Projects Completed</span>
                    </div>
                </FadeInDivOnScroll>
                <FadeInDivOnScroll class='f-i-f-b'>
                    <div className='mobile-our-vision-badge'>
                        <span className='mobile-our-vision-badge-value'>05</span>
                        <span className='mobile-our-vision-badge-name'>Creative Minds</span>
                    </div>
                </FadeInDivOnScroll>
                <FadeInDivOnScroll class='f-i-f-b'>
                    <div className='mobile-our-vision-badge'>
                        <span className='mobile-our-vision-badge-value'>03+</span>
                        <span className='mobile-our-vision-badge-name'>Years of Experience</span>
                    </div>
                </FadeInDivOnScroll>
                <FadeInDivOnScroll class='f-i-f-b'>
                    <div className='mobile-our-vision-badge'>
                        <span className='mobile-our-vision-badge-value'>10+</span>
                        <span className='mobile-our-vision-badge-name'>Clients</span>
                    </div>
                </FadeInDivOnScroll>
            </div>
        </div>
    )
}

export default OurVision