import React from 'react'
import './Whatwedo.css'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'

function WhatwedoBox(props) {
    return (
        <div className='what-we-do-box' style={props.style}>
            <div className='what-we-do-box-background my-absolute'/>
            <FadeInDivOnScroll class={props.textAnimation}>
                <div className="what-we-do-box-foreground my-flex jc-space-bw">
                    <div className="what-we-do-text">
                        <div className='what-we-do-text-heading sk-modernist-b' style={props.width}>{props.head}</div>
                        <div className="what-we-do-text-desc sk-modernist-l">{props.desc}</div>
                    </div>

                    <div className="what-we-do-learn-more my-flex ai-center my-pointer sk-modernist-l my-button-click-w">
                        <span>Learn More</span>
                        <img src="homepage_assets/images/icons/arrow-right.png" alt="arrow right"/>
                    </div>
                </div>
            </FadeInDivOnScroll>
        </div>
    )
}

function Whatwedo() {
    return (
        <div className="what-we-do my-flex ai-center">
            <div className='what-we-do-heading-min'>
                <FadeInDivOnScroll class='what-we-do-heading f-i-f-b'>
                    <img src='homepage_assets/images/WHAT-WE-DO.png' alt=''/>
                </FadeInDivOnScroll>
            </div>

            <div className='what-we-do-quotes-min'>
                <FadeInDivOnScroll class='what-we-do-quote f-i-f-b sk-modernist-l'>
                    Delivering Tailored Digital Solutions
                </FadeInDivOnScroll>
            </div>

            <div className="our-services my-flex ai-center jc-center sk-modernist-l">
                <div className="my-button-click-w">Our Services</div>
            </div>

            <div className="what-we-do-services o-hidden">
                <WhatwedoBox textAnimation='what-we-do-text-animation-left' width={{width: '65%'}}
                             style={{borderStyle: 'solid', borderWidth: '0px 0px 3px 0px', borderImage: 'linear-gradient(to left, rgba(14, 19, 64, 1), rgba(0, 0, 0, 0)) 1'}} head={"Branding & Visual Design"}
                             desc={"Our branding and visual design services help bring your brand to life through thoughtful, compelling design. From logo creation to complete brand identity development, we craft visuals that resonate with your audience."}></WhatwedoBox>
                <WhatwedoBox textAnimation='what-we-do-text-animation-center' width={{width: '65%'}}
                             style={{borderStyle: 'solid', borderWidth: '0px 3px 3px 3px', borderImage: 'linear-gradient(to top, rgba(14, 19, 64, 1), rgba(0, 0, 0, 0)) 1'}} head={"User Interface Design (UI)"}
                             desc={"We specialize in crafting intuitive and visually engaging user interfaces that enhance the overall digital experience. Our Ul design services focus on creating interfaces that are not only aesthetically appealing but also easy to navigate."}></WhatwedoBox>
                <WhatwedoBox textAnimation='what-we-do-text-animation-right' width={{width: '65%'}}
                             style={{borderStyle: 'solid', borderWidth: '0px 0px 3px 0px', borderImage: 'linear-gradient(to right, rgba(14, 19, 64, 1), rgba(0, 0, 0, 0)) 1'}} head={"User Experience Design (UX)"}
                             desc={"Prioritize the needs and emotions of your users, ensuring an intuitive and enjoyable interaction with your product. We take a user-centered approach, combining research, wireframing, and prototyping to create seamless journeys that make your digital platforms easy to use."}></WhatwedoBox>
                <WhatwedoBox textAnimation='what-we-do-text-animation-left' width={{width: '55%'}} head={"App Development"}
                             desc={"Our app development services transform your ideas into fully functional, user- friendly mobile applications. Whether for IOS or Android, we build custom apps designed to meet your business goals and provide exceptional user experiences."}></WhatwedoBox>
                <WhatwedoBox textAnimation='what-we-do-text-animation-center' width={{width: '47%'}}
                             style={{borderStyle: 'solid', borderWidth: '0px 3px 0px 3px', borderImage: 'linear-gradient(to bottom, rgba(14, 19, 64, 1), rgba(0, 0, 0, 0)) 1'}} head={"Website & E-commerce"}
                             desc={"We offer comprehensive website and e- commerce development services that bring your digital presence to life. Whether you need a corporate website or a full- fledged online store, we build responsive, user-friendly, and visually appealing platforms tailored to your brand's needs."}></WhatwedoBox>
                <WhatwedoBox textAnimation='what-we-do-text-animation-right' width={{width: '50%'}} head={"Digital Marketing"}
                             desc={"Designed to boost your brand's online presence and drive measurable results. We create tailored strategies that include search engine optimization (SEO), social media marketing, email campaigns, and paid advertising to help you reach your target audience effectively."}></WhatwedoBox>
            </div>
        </div>
    )
}

export default Whatwedo