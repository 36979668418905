import React from 'react'
import './ClientsMobile.css'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'

function ClientsMobile() {
    return (
        <div className='mobile-clients'>
            <FadeInDivOnScroll class='z-i-m'>
                <div className="mobile-clients-head sk-modernist">Clients</div>
            </FadeInDivOnScroll>
            <FadeInDivOnScroll class='z-i-m'>
                <div className="mobile-clients-body sk-modernist">Explore our client roster across industries, benefiting from our tailored solutions that enhance digital experiences.</div>
            </FadeInDivOnScroll>
            <FadeInDivOnScroll class='z-i-m'>
                <div className="mobile-clients-images my-flex ai-center jc-center">
                    <img src="homepage_assets/images/clients-images.png" alt="clients images" />
                </div>
            </FadeInDivOnScroll>
        </div>
    )
}

export default ClientsMobile