import React from 'react'
import './About.css'

function About() {

  return (
    <>
      <div>About</div>
    </>
  )
}

export default About