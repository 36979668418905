import React from 'react'
import './ServicesMobile.css'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'
import { Link } from 'react-router-dom'

function ServiceBox(props) {
    return (
        <div className='mobile-service-box' style={props.border}>
            <FadeInDivOnScroll class='f-i-r-l-m'>
                <div className="mobile-service-box-head sk-modernist ">{props.head}</div>
            </FadeInDivOnScroll>
            <FadeInDivOnScroll class='f-i-r-l-m'>
                <div className="mobile-service-box-body sk-modernist-l">{props.body}</div>
            </FadeInDivOnScroll>
            <FadeInDivOnScroll class='f-i-r-l-m'>
                <div className="mobile-service-box-learn-more my-flex ai-center sk-modernist my-no-select my-button-click">
                    <div className="mobile-service-box-learn-more-text">Learn More</div>
                    <img className="mobile-service-box-learn-more-icon" src='homepage_assets/images/icons/arrow-right.png' alt=''></img>
                </div>
            </FadeInDivOnScroll>
        </div>
    )
}

function ServicesMobile() {
    return (
        <div className='mobile-services o-hidden'>
            <FadeInDivOnScroll class='f-i-r-l'>
                <img className="mobile-services-animation-ball " src="homepage_assets/gif/achievement-giff.gif" alt="" />
            </FadeInDivOnScroll>

            <div className='mobile-services-others'>
                <FadeInDivOnScroll class='z-i-m'>
                    <div className="mobile-services-head sk-modernist">Services</div>
                </FadeInDivOnScroll>

                <div className="mobile-services-list">
                    <ServiceBox border={{ borderStyle: 'solid', borderWidth: '0px 0px 3px 0px', borderImage: 'linear-gradient(to left, rgba(14, 19, 64, 1) 55%, rgba(0, 0, 0, 0) 100%) 1' }} head='Branding & Visual Design' body='Explore our client roster across industries, benefiting from our tailored solutions that enhance digital experiences.'></ServiceBox>
                    <ServiceBox border={{ borderStyle: 'solid', borderWidth: '0px 0px 3px 0px', borderImage: 'linear-gradient(to right, rgba(14, 19, 64, 1) 55%, rgba(0, 0, 0, 0) 100%) 1' }} head='User Interface Design (UI)' body='Explore our client roster across industries, benefiting from our tailored solutions that enhance digital experiences.'></ServiceBox>
                    <ServiceBox border={{ borderStyle: 'solid', borderWidth: '0px 0px 3px 0px', borderImage: 'linear-gradient(to left, rgba(14, 19, 64, 1) 55%, rgba(0, 0, 0, 0) 100%) 1' }} head='User Experience Design (UX) ' body='Explore our client roster across industries, benefiting from our tailored solutions that enhance digital experiences.'></ServiceBox>
                    <ServiceBox border={{ borderStyle: 'solid', borderWidth: '0px 0px 3px 0px', borderImage: 'linear-gradient(to right, rgba(14, 19, 64, 1) 55%, rgba(0, 0, 0, 0) 100%) 1' }} head='Website & E-commerce' body='Explore our client roster across industries, benefiting from our tailored solutions that enhance digital experiences.'></ServiceBox>
                    <ServiceBox border={{ borderStyle: 'solid', borderWidth: '0px 0px 3px 0px', borderImage: 'linear-gradient(to left, rgba(14, 19, 64, 1) 55%, rgba(0, 0, 0, 0) 100%) 1' }} head='App Development' body='Explore our client roster across industries, benefiting from our tailored solutions that enhance digital experiences.'></ServiceBox>
                    <ServiceBox head='Digital Marketing' body='Explore our client roster across industries, benefiting from our tailored solutions that enhance digital experiences.'></ServiceBox>
                </div>
                <div className="mobile-services-get-in-touch my-flex ai-center jc-center sk-modernist my-no-select my-button-click"><Link to='/contact'>Get In Touch</Link></div>
            </div>
        </div>
    )
}

export default ServicesMobile