import React, {useState} from 'react'
import './bottomContact.css'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'
import api from "../../../lib/api";

function BottomContact() {

    const [selectedOption, setSelectedOption] = useState(null);

    const options = [
        {label: 'Logo Design / Branding', value: 1},
        {label: 'Graphic Design', value: 2},
        {label: 'Web UI/UX Design', value: 3},
        {label: 'Web Development', value: 4},
        {label: 'App Development', value: 5},
        {label: 'Digital Marketing', value: 6},
    ];

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        phone: '',
        lookingFor: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleChangeOption = (e) => {
        setSelectedOption(e.target.value);
        formData.lookingFor = e.target.value;
    };

    const email = 'info@tevahdesign.com';
    const subject = 'Hello!';
    const body = 'I hope you are doing well. I need a service...';

    const handleEmailClick = () => {
        const gmailLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${email}&su=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        window.open(gmailLink, '_blank');
    };

    const handleSendEmail = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/sendContact', formData);

            if (response.status === 200) {  // Only navigate if the email is sent successfully
                // console.log("Email sent successfully");
                alert("Email sent successfully.");
            } else {
                // console.error("Failed to send email.");
                alert("There was an issue sending the email. Please try again.");
            }
        } catch (error) {
            // console.error("There was an error:", error);
            alert("There was an error. Please try again.");
        }
    }

    return (
        <div className="project-contact my-flex jc-space-bw">
            <div className="contact-left">
                <FadeInDivOnScroll class='contact-left-gif-animation'>
                    <img src="homepage_assets/gif/out_of_the_box.gif" alt="out of the box" className="contact-left-gif"/>
                </FadeInDivOnScroll>

                <FadeInDivOnScroll class='contact-left-head-animation'>
                    <div className="contact-left-head sk-modernist">Have a project in mind?</div>
                </FadeInDivOnScroll>

                <FadeInDivOnScroll class='contact-left-desc-animation'>
                    <div className="contact-left-desc sk-modernist">At <span className='sk-modernist-b'>TEVAH</span>, we don't just create designs; we craft experiences that resonate. Join us on the journey of
                        transforming ideas into impactful realities. Let's build something extraordinary together.
                    </div>
                </FadeInDivOnScroll>

                <div className="contact-left-box my-flex jc-space-bw">

                    <FadeInDivOnScroll class='contact-left-gif-animation'>
                        <div className="contact-left-email">
                            <div className="contact-left-email-head my-flex ai-center">
                                <img src="homepage_assets/images/social/email.png" alt="" className="contact-left-email-icon"/>
                                <span className='sk-modernist-b'>Email</span>
                            </div>

                            <div className="contact-left-email-body sk-modernist-l my-pointer my-button-click-w" onClick={handleEmailClick}>info@tevahdesign.com</div>
                        </div>
                    </FadeInDivOnScroll>

                    <FadeInDivOnScroll class='contact-left-gif-animation'>
                        <div className="contact-left-phone">
                            <div className="contact-left-phone-head my-flex ai-center">
                                <img src="homepage_assets/images/social/phone.png" alt="" className="contact-left-phone-icon"/>
                                <span className='sk-modernist-b'>Phone</span>
                            </div>
                            <div className="contact-left-phone-body sk-modernist-l">XXX XXX XXXX</div>
                        </div>
                    </FadeInDivOnScroll>
                </div>
            </div>

            <div className="contact-right sk-modernist">
                <form action="submit" onSubmit={handleSendEmail}>
                    <div className="contact-right-details">
                        <div className="contact-right-details-entry">
                            <p>First Name*</p>
                            <input className='contact-first-name-input'
                                   value={formData.firstName}
                                   onChange={handleChange}
                                   name='firstName'
                                   type="text" required/>
                        </div>

                        <div className="contact-right-details-entry">
                            <p>Last Name*</p>
                            <input className='contact-last-name-input'
                                   value={formData.lastName}
                                   onChange={handleChange}
                                   name='lastName'
                                   type="text" required/>
                        </div>
                    </div>

                    <div className="contact-right-details">
                        <div className="contact-right-details-entry">
                            <p>Email*</p>
                            <input className='contact-email-input'
                                   value={formData.email}
                                   onChange={handleChange}
                                   name='email'
                                   type="email" required/>
                        </div>

                        <div className="contact-right-details-entry">
                            <p>Phone Number*</p>
                            <input className='contact-phone-input'
                                   value={formData.phone}
                                   onChange={handleChange}
                                   name='phone'
                                   maxLength={10}
                                   type="text" required/>
                        </div>
                    </div>

                    <div className="contact-right-details">
                        <div className="contact-right-details-entry">
                            <p>Company*</p>
                            <input className='contact-company-input'
                                   value={formData.companyName}
                                   onChange={handleChange}
                                   name='companyName'
                                   type="text"/>
                        </div>

                        <div className="contact-right-details-entry">
                            <p>Service looking for?</p>
                            <select className='contact-right-role-input'
                                    value={selectedOption}
                                    onChange={handleChangeOption}
                                    name="contact-role"
                                    id="contact-role">
                                {options.map((option) => (
                                    <option key={option.value} value={option.label}>
                                        {option.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>

                    <div className="contact-right-message contact-right-details-entry">
                        <p>Message*</p>
                        <input className='contact-message-input'
                               value={formData.message}
                               onChange={handleChange}
                               name='message'
                               type="text" required/>
                    </div>

                    <button className="contact-right-submit">
                        <div className="my-flex ai-center jc-center my-button-click-w">Submit</div>
                    </button>
                </form>
            </div>
        </div>
    )
}

export default BottomContact