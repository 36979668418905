import Homepage from './Web/Homepage/Homepage';
import HomepageMobile from './Mobile/Homepage/HomepageMobile';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Services from './Web/Services/Services';
import Work from './Web/Work/Work';
import About from './Web/About/About';
import {useState, useEffect} from 'react';
import './App.css';
import ContactMobile from './Mobile/Homepage/Contact/ContactMobile';

function App() {

    const [key, setKey] = useState(0);

    useEffect(() => {

        const handleWheel = (e) => {
            if (e.ctrlKey || e.metaKey) {
                e.preventDefault();
            }
        };

        window.addEventListener('wheel', handleWheel, {passive: false});

        const handleResize = () => {
            if (window.innerWidth > 450) {
                setKey((prevKey) => prevKey + 1); // Increment key to force re-render
            }
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('wheel', handleWheel);
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <BrowserRouter>
            <Routes key={key}>
                <Route path="/" element={window.innerWidth > 500 ? <Homepage/> : <HomepageMobile/>}/>
                <Route path="/services" element={window.innerWidth > 500 ? <Services/> : ''}/>
                <Route path="/work" element={window.innerWidth > 500 ? <Work/> : ''}/>
                <Route path="/about" element={window.innerWidth > 500 ? <About/> : ''}/>
                <Route path="/contact" element={<ContactMobile/>}/>
            </Routes>
        </BrowserRouter>
    );
}

export default App;