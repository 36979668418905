import React, {useState, useEffect} from 'react'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'
import './HomeIntroduction.css'

function HomeIntroduction() {

    const [visibleText, setVisibleText] = useState(1); // Track which text is visible

    useEffect(() => {
        const interval = setInterval(() => {
            setVisibleText((prevText) => (prevText === 1 ? 2 : 1));
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className="home-introduction my-flex ai-center">
            <div className="home-introduction-background my-flex ai-center jc-space-bw">
                <div className="home-introduction-quote my-flex">
                    <div className="home-introduction-main-quote my-flex ai-center sk-modernist-l">
                        <FadeInDivOnScroll class="f-i-l-r">
                            {visibleText === 1 ?
                                <div key='quote-1' className='home-introduction-quote-1'>Leading the Way in UI/UX Design</div>
                                :
                                <div key='quote-2' className="home-introduction-quote-2">Curating Experiences that Inspire and Transform!</div>
                            }
                        </FadeInDivOnScroll>
                    </div>

                    <FadeInDivOnScroll class="home-introduction-sub-quote my-flex ai-center my-pointer f-i-l-r">
                        <div className="home-introduction-sub-quote-text sk-modernist-l my-button-click-w">Our Capabilities</div>
                        <img className="home-introduction-sub-quote-icon" src='homepage_assets/images/icons/arrow-right.png' alt=''></img>
                    </FadeInDivOnScroll>
                </div>

                <FadeInDivOnScroll class="home-introduction-gif f-i-r-l">
                    <img src="homepage_assets/gif/first.gif" alt='intro gif'></img>
                </FadeInDivOnScroll>
            </div>

            <div className="home-hidden-part my-flex jc-space-bw">
                <FadeInDivOnScroll class="home-hidden-part-left sk-modernist-b m-i-r-l">
                    Digitize brilliance, Humanize impact
                </FadeInDivOnScroll>

                <FadeInDivOnScroll class="home-hidden-part-right m-i-l-r">
                    <div className="home-hidden-part-right-top sk-modernist">Experience design (XD) plays a crucial role in the success of digital products by ensuring they are user-centered, functional, and emotionally
                        engaging.
                    </div>

                    <div className="home-hidden-part-right-bottom my-flex ai-center my-pointer my-button-click-w">
                        <div className="home-hidden-part-lets-connect sk-modernist">Let's Connect</div>
                        <img src="homepage_assets/images/icons/hidden-part-arrow-right-circle.png" alt="arrow right icon"/>
                    </div>
                </FadeInDivOnScroll>
            </div>
        </div>
    )
}

export default HomeIntroduction