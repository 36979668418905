import React, { useEffect, useState } from 'react'
import NavbarMobile from '../../MobileComponents/Navbar/NavbarMobile'
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll'
import './HomeIntroduction.css'

function SlidingBox(props) {
    return (
        <div className='sliding-box my-flex ai-center'>
            <FadeInDivOnScroll class='z-i-m'>
                <div className="sliding-box-text">{props.text}</div>
            </FadeInDivOnScroll>
        </div>
    )
}

function HomeIntroduction() {

    const [visibleText, setVisibleText] = useState(1); // Track which text is visible

    useEffect(() => {
        const interval = setInterval(() => {
            setVisibleText((prevText) => (prevText === 1 ? 2 : 1));
        }, 4000);

        return () => clearInterval(interval);
    }, []);

    return (
        <div className='mobile-home-introduction my-flex jc-center'>
            <NavbarMobile ></NavbarMobile>

            <div className="mobile-home-intro-gif my-absolute o-x-hidden">
                <FadeInDivOnScroll class="f-i-r-l">
                    <img src="homepage_assets/gif/first.gif" alt='intro gif'></img>
                </FadeInDivOnScroll>
            </div>

            <div className="mobile-home-intro-rest my-flex">
                <div className="mobile-home-intro-quote">
                    <FadeInDivOnScroll class="mobile-home-intro-main-quote sk-modernist f-i-l-r">
                        {visibleText === 1 ?
                            <div key='quote-1' className="mobile-home-intro-quote-1 f-i-l-r">Leading the Way in UI/UX Design</div>
                            :
                            <div key='quote-2' className="mobile-home-intro-quote-2 f-i-l-r">Curating Experiences that Inspire and Transform!</div>
                        }
                    </FadeInDivOnScroll>

                    <div className="my-button-click">
                        <FadeInDivOnScroll class="mobile-home-intro-sub-quote my-flex ai-center f-i-l-r my-no-select">
                            <div className="mobile-home-intro-sub-quote-text sk-modernist-l">Our Capabilities</div>
                            <img className="mobile-home-intro-sub-quote-icon" src='homepage_assets\images\icons\arrow-right.png' alt=''></img>
                        </FadeInDivOnScroll>
                    </div>
                </div>

                <div className="mobile-home-sliding-box-list sk-modernist-b my-flex ai-center">
                    <SlidingBox text='Impactful UI/UX Design'></SlidingBox>
                    <SlidingBox text='Brand Identity Design'></SlidingBox>
                    <SlidingBox text='Dynamic Experience'></SlidingBox>
                </div>
            </div>
        </div>
    )
}

export default HomeIntroduction
