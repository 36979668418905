import React, { useState, useRef, useEffect } from "react";

const FadeInDivOnScroll = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const divRef = useRef(null);

    useEffect(() => {

        const currentDiv = divRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false); // Hide div when it leaves the viewport
                    }
                });
            },
            {
                rootMargin: window.innerWidth < 1000 ? "20px 0px 20px 0px" : "100px 0px 50px 0px",
                threshold: 0.01 // Trigger when 1% of the element is visible
            }
        );

        if (currentDiv) {
            observer.observe(currentDiv);
        }

        return () => {
            if (currentDiv) {
                observer.unobserve(currentDiv);
            }
        };
    }, []);

    return (
        <div
            ref={divRef}
            className={`fade-out ${isVisible ? `${props.class}` : ""}`}
        >
            {props.children}
        </div>
    );
};

export default FadeInDivOnScroll