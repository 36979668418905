import React, {useEffect, useState, useRef} from 'react'
import NavbarMobile from '../../MobileComponents/Navbar/NavbarMobile'
import Select from 'react-select';
import {useNavigate} from 'react-router-dom';
import FadeInDivOnScroll from '../../../Components/FadeInAnimation/FadeInDivScroll';
import api from '../../../lib/api';
import './ContactMobile.css'

function ContactMobile() {

    const [selectedOption, setSelectedOption] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const options = [
        {label: 'Logo Design / Branding', value: 1},
        {label: 'Graphic Design', value: 2},
        {label: 'Web UI/UX Design', value: 3},
        {label: 'Web Development', value: 4},
        {label: 'App Development', value: 5},
        {label: 'Digital Marketing', value: 6},
    ];

    const customStylesDropdown = {
        control: (provided, state) => ({
            ...provided,
            outline: 'none',
            boxShadow: 'none',
            border: state.isFocused ? '1.6px solid #1942F2' : '1.3px solid black',
        }),
        placeholder: (provided) => ({
            ...provided,
            fontSize: '14px',
            fontFamily: 'Arial',
            color: '#676767',
        }),
    };

    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        companyName: '',
        phone: '',
        lookingFor: '',
        message: ''
    });

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleChangeOption = (option) => {
        setSelectedOption(option);
        formData.lookingFor = option.label
    };

    const handleSendEmail = async (e) => {
        e.preventDefault();
        try {
            const response = await api.post('/sendContact', formData);

            if (response.status === 200) {  // Only navigate if the email is sent successfully
                // console.log("Email sent successfully");
                navigate('/');
            } else {
                // console.error("Failed to send email.");
                alert("There was an issue sending the email. Please try again.");
            }
        } catch (error) {
            // console.error("There was an error:", error);
            alert("There was an error. Please try again.");
        }
    }

    return (
        <div className='mobile-contact-form my-flex ai-center my-no-select'>
            <NavbarMobile></NavbarMobile>

            <div className="mobile-contact-form-header sk-modernist">
                <FadeInDivOnScroll class='f-i-r-l'>
                    <div className="mobile-contact-form-header-head ">Let’s conquer it together</div>
                </FadeInDivOnScroll>

                <FadeInDivOnScroll class='f-i-r-l'>
                    <div className="mobile-contact-form-header-subhead">Tell us about your project requirement</div>
                </FadeInDivOnScroll>
            </div>

            <div className="mobile-contact-form-body">
                <form action="submit" onSubmit={handleSendEmail}>

                    <FadeInDivOnScroll class='f-i-f-b'>
                        <div className="mobile-contact-form-details-entry">
                            <p>First name*</p>
                            <input className='mobile-contact-form-details-entry-input'
                                   value={formData.firstName}
                                   onChange={handleChange}
                                   name='firstName'
                                   placeholder='Enter first name'
                                   type="text" required/>
                        </div>
                    </FadeInDivOnScroll>

                    <FadeInDivOnScroll class='f-i-f-b-2'>
                        <div className="mobile-contact-form-details-entry">
                            <p>Last name*</p>
                            <input className='mobile-contact-form-details-entry-input'
                                   value={formData.lastName}
                                   onChange={handleChange}
                                   name='lastName'
                                   placeholder='Enter last name'
                                   type="text" required/>
                        </div>
                    </FadeInDivOnScroll>

                    <FadeInDivOnScroll class='f-i-f-b-2'>
                        <div className="mobile-contact-form-details-entry">
                            <p>Email*</p>
                            <input className='mobile-contact-form-details-entry-input'
                                   value={formData.email}
                                   onChange={handleChange}
                                   name='email'
                                   placeholder='Enter email'
                                   type="email" required/>
                        </div>
                    </FadeInDivOnScroll>

                    <FadeInDivOnScroll class='f-i-f-b-3'>
                        <div className="mobile-contact-form-details-entry">
                            <p>Company name</p>
                            <input className='mobile-contact-form-details-entry-input'
                                   value={formData.companyName}
                                   onChange={handleChange}
                                   name='companyName'
                                   placeholder='Enter company name'
                                   type="text"/>
                        </div>
                    </FadeInDivOnScroll>

                    <FadeInDivOnScroll class='f-i-f-b-3'>
                        <div className="mobile-contact-form-details-entry">
                            <p>Phone number*</p>
                            <div className="mobile-contact-phone-input-combo my-flex">
                                <input className='mobile-contact-phone-code-input' value='+91' type="text" disabled/>
                                <input className='mobile-contact-phone-input'
                                       value={formData.phone}
                                       onChange={handleChange}
                                       name='phone'
                                       placeholder='Phone Number'
                                       type="text" required/>
                            </div>
                        </div>
                    </FadeInDivOnScroll>

                    <FadeInDivOnScrollSelect class='f-i-f-b-select'>
                        <div className="mobile-contact-form-details-entry">
                            <p>What service are you looking for?</p>
                            <Select id="mobile-contact-form-service-input"
                                    options={options}
                                    value={selectedOption}
                                    onChange={handleChangeOption}
                                    styles={customStylesDropdown}
                                    placeholder="Select an option"/>
                        </div>
                    </FadeInDivOnScrollSelect>

                    <FadeInDivOnScroll class='f-i-f-b-4'>
                        <div className="mobile-contact-form-details-entry">
                            <p>Message*</p>
                            <textarea className='mobile-contact-form-details-entry-input mobile-contact-form-details-entry-textarea'
                                      value={formData.message}
                                      onChange={handleChange}
                                      name='message'
                                      placeholder='Enter your message'
                                      required/>
                        </div>
                    </FadeInDivOnScroll>

                    <FadeInDivOnScroll class='f-i-f-b-4'>
                        <div className="mobile-contact-form-footer">By submitting, you agree to receive marketing and sales communication via email and call from Tevah Design.</div>
                    </FadeInDivOnScroll>
                    <button className="mobile-contact-form-submit my-flex ai-center jc-center sk-modernist my-no-select my-button-click">Submit</button>
                </form>
            </div>
        </div>
    )
}

export default ContactMobile


const FadeInDivOnScrollSelect = (props) => {

    const [isVisible, setIsVisible] = useState(false);
    const [classValue, setClassValue] = useState(props.class);
    const divRef = useRef(null);

    useEffect(() => {

        const currentDiv = divRef.current;

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setIsVisible(true);
                    } else {
                        setIsVisible(false); // Hide div when it leaves the viewport
                    }
                });
            },
            {
                rootMargin: "20px 0px 20px 0px",
                threshold: 0.01 // Trigger when 1% of the element is visible
            }
        );

        if (currentDiv) {
            observer.observe(currentDiv);
        }

        return () => {
            if (currentDiv) {
                observer.unobserve(currentDiv);
            }
        };
    }, []);

    if (props.class === 'f-i-f-b-select') {
        setTimeout(() => {
            setClassValue('f-i-f-b-select-stable');
        }, 1200);
    }

    return (
        <div
            ref={divRef}
            className={`fade-out ${isVisible ? `${classValue}` : ""}`}
        >
            {props.children}
        </div>
    );
};