import React from 'react'
import { Link } from 'react-router-dom';
import { useState, useEffect } from 'react';
import HomeIntroduction from './HomeIntroduction/HomeIntroduction'
import ExperienceDesign from './ExperienceDesign/ExperienceDesign'
import ProjectsMobile from './Projects/ProjectsMobile';
import ClientsMobile from './Clients/ClientsMobile';
import ServicesMobile from './Services/ServicesMobile';
import OurVision from './OurVision/OurVision';
import BottomAboutMobile from './About/BottomAboutMobile';
import './HomepageMobile.css'

function HomepageMobile() {

    const [key, setKey] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setKey((prevKey) => prevKey + 1); // Increment key to force re-render
        }, 750);

        return () => clearTimeout(timer);
    }, []);

    return (
        <div className='mobile-home my-no-select'>
            <div key={key} className="re-render"></div>

            {/* ////////// INTRODUCTION ////////// */}

            <HomeIntroduction></HomeIntroduction>

            {/* ////////// EXPERIENCE DESIGN ////////// */}

            <ExperienceDesign></ExperienceDesign>

            {/* ////////// PROJECTS ////////// */}

            <ProjectsMobile></ProjectsMobile>

            {/* ////////// CLIENTS ////////// */}

            <ClientsMobile></ClientsMobile>

            {/* ////////// SERVICES ////////// */}

            <ServicesMobile></ServicesMobile>

            {/* ////////// OUR VISION ////////// */}

            <OurVision></OurVision>


            {/* ////////// CONTACT US ////////// */}


            <div className="mobile-contact-us my-flex ai-center jc-space-bw my-absolute">
                <div className="mobile-contact-us-text sk-modernist">Have a project in mind?</div>
                <div className="mobile-contact-us-button sk-modernist my-flex ai-center jc-center my-no-select my-button-click"><Link to='/contact'>Contact Us</Link></div>
            </div>


            {/* ////////// ABOUT ////////// */}

            <BottomAboutMobile></BottomAboutMobile>

        </div>
    )
}

export default HomepageMobile

